const Texts = {
  PT: {
    title: "Como funciona a Game Jam Plus?",
    subtitle: "A competição é dividida em quatro etapas"
  },
  EN: {
    title: "How does the Game Jam Plus work?",
    subtitle: "The competition is divided into four stages"
  }
}

export default Texts