import etapa1 from '../../assets/images/etapa01.png'
import etapa2 from '../../assets/images/etapa02.png'
import etapa3 from '../../assets/images/etapa03.png'
import etapa4 from '../../assets/images/etapa03.png'

const Texts = {
  PT: {
    etapa1: {
      image: etapa1,
      title: "1ª Etapa | Game Jam",
      date: "09 a 11 de Outubro",
      text: "Começamos como uma Game Jam normal: todos os participantes irão se juntar nos locais registrados, propor ideias, formar equipes e terão 54h para desenvolver um protótipo do jogo e fazer o pitch (apresentação curta e direta) sobre seu projeto. No final desta etapa, cada equipe apresentará seu jogo para uma banca de jurados, simulando um pitch para investidores ou uma publisher. Vale ressaltar a importância de um bom planejamento de marketing e negócios, pois terão peso relevante na avaliação da apresentação. Com isso, teremos definido a primeira equipe vencedora de cada regional. A segunda equipe vencedora será definida a critério da organização local.",
      child: {
        content: "*Devido à pandemia do Covid-19 (Coronavírus), a etapa pode ser realizada online, ficará a critério da organização local.",
        list: []
      }
    },
    etapa2: {
      image: etapa2,
      title: "2ª Etapa | Aceleração das Equipes Finalistas",
      date: "Novembro, Dezembro e Janeiro",
      text: "Esta etapa é focada em capacitar as equipes a melhorarem seus projetos até a Final. Cada equipe vai receber acesso a dois meios de capacitação: mentorias direcionadas e workshops online. É importante ressaltar que as mentorias direcionadas são benefícios exclusivos para os finalistas da 1ª etapa. Nelas, conectaremos os participantes a profissionais renomados da indústria. Já os workshops online funcionarão tanto para os finalistas quanto para os demais participantes inscritos na primeira etapa, sendo alguns deles acesso exclusivo para finalistas.",
      child: {
        content: "",
        list: []
      }
    },
    etapa3: {
      image: etapa3,
      title: "3ª Etapa | Semifinais Continentais",
      date: "Fevereiro",
      text: "Esta etapa contará com uma grande semifinal continental, onde os vencedores da 1ª etapa passarão por uma avaliação e serão selecionados os grandes finalistas de cada continente para a super final em São Paulo em Janeiro de 2021. As semifinais serão:",
      child: {
        content: "",
        list: ['América Latina', 'África e Oriente Médio', 'América do Norte', 'Europa', 'Ásia e Oceania']
      }
    },
    etapa4: {
      image: etapa4,
      title: "4ª Etapa | A Grande Final",
      date: "5 a 9 de Maio",
      text: "É o momento em que os finalistas e organizadores se encontram presencialmente na cidade de São Paulo, a fim de definir e celebrar os grandes vencedores. Esta etapa irá proporcionar momentos únicos de networking, trocas de experiência e claro, muitos jogos. Além das premiações das categorias principais e extras, os jogos poderão obter conquistas específicas.",
      child: {
        content: "",
        list: []
      }
    }
  },
  EN: {
    etapa1: {
      image: etapa1,
      title: "1st Stage | Game Jam",
      date: "October 09th to 11th",
      text: "We start as any Game Jam: all participants will gather at the registered places, propose ideas, form teams and will have 54 hours to develop a prototype of the game and make the pitch (short and direct presentation) about their project. At the end of this stage, each team will present their game to a panel of judges, simulating a pitch for investors or a publisher. It is worth mentioning the importance of good marketing and business planning, as they will have a relevant weight in the evaluation of the presentation. With that, we will have defined the first winning team of each regional. The second winning team will be defined at the discretion of the local organization",
      child: {
        content: "*Due to the Covid-19 (Coronavirus) pandemic, the stage can be performed online. This will be at the discretion of the local organization.",
        list: []
      }
    },
    etapa2: {
      image: etapa2,
      title: "2nd Stage | Final Team Acceleration",
      date: "November, December and January",
      text: "This stage is focused on enabling teams to improve their projects until the Final. Each team will receive access to two means of training: targeted mentoring and online workshops. It is important to note that targeted mentoring is a benefit that is exclusive to the finalists of the 1st stage. In it, we will connect participants to renowned industry professionals. The online workshops will work both for the finalists and for the other participants registered in the first stage, with some of them being exclusive to the finalists.",
      child: {
        content: "",
        list: []
      }
    },
    etapa3: {
      image: etapa3,
      title: "3rd Stage | Continental Semifinals",
      date: "February",
      text: "This stage will feature a large continental semifinal, where the winners of the 1st stage will undergo an evaluation and the grand finalists from each continent will be selected for the super final in São Paulo in January 2021.",
      child: {
        content: "",
        list: ['Latin America', 'Africa and the Middle East', 'North America', 'Europe', 'Asia and Oceania']
      }
    },
    etapa4: {
      image: etapa4,
      title: "4th Stage | The Grand Final",
      date: "May 5th to 9th",
      text: "It is the moment when the finalists and organizers meet in person in the city of São Paulo, in order to define and celebrate the big winners. This stage will provide unique moments of networking, exchanges of experience and of course, many games. In addition to the awards in the main and extra categories, games can earn specific achievements.",
      child: {
        content: "",
        list: []
      }
    }
  }
}

export default Texts