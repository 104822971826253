import React from 'react';
import styled from 'styled-components'

const EtapaBox = (props) => (
  <Container>
    <Image src={props.image} alt='' />
    <Content>
      <Title>{props.title}</Title>
      <Subtitle>{props.subtitle}</Subtitle>
      <Text>{props.text}</Text>
      <Box>
        {props.children}
      </Box>
    </Content>
  </Container>
)

export default EtapaBox

const Container = styled.section`
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 20px;
  margin-top: 64px;
  margin-bottom: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 768px) {
    margin-top: 32px;
    flex-direction: column;
  }
`

const Image = styled.img`
  width: 30%;
  
  @media screen and (max-width: 768px) {
    width: 80%;
    margin-bottom: 16px;
    order: -1;
  }
`

const Content = styled.div`
  width: 65%;
  display: flex;
  flex-direction: column;
  
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

const Title = styled.h2`
  margin-bottom: 8px;
  font-family: var(--primary);
  font-size: 2.5rem;
  line-height: 3rem;
  
  @media screen and (max-width: 768px) {
    margin-bottom: 8px;
    font-size: 2rem;
    text-align: center;
    line-height: 2.5rem;
  }
`

const Subtitle = styled.h3`
  margin-bottom: 1rem;
  font-family: var(--primaryLight);
  font-size: 2rem;
  color: #834769;
  line-height: 2.5rem;
  
  @media screen and (max-width: 768px) {
    margin-bottom: 16px;
    font-size: 1.75rem;
    text-align: center;
    line-height: 2rem;
  }
`

const Text = styled.p`
  font-family: var(--regular);
  font-size: 1.5rem;
  color: #929292;
  line-height: 2rem;
  margin-bottom: 1rem;
  
  @media screen and (max-width: 768px) {
    font-size: 1rem;
    text-align: justify;
    line-height: 1.5rem;
  }
`
const Box = styled.div`
  font-family: var(--regular);
  font-size: 1.5rem;
  color: #929292;
  line-height: 2rem;



  ol, ul{
    list-style: lower-roman;;
    margin-left: 2rem;

    li{
      margin-bottom: .5rem;
    }
  }
  
  @media screen and (max-width: 768px) {
    font-size: 1rem;
    text-align: justify;
    line-height: 1.5rem;
  }
`