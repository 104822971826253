import React from 'react';

import Layout from "../components/Layout"
import Language from "../components/Language"
import Seo from "../components/seo"
import ComoFunciona from "../components/ComoFunciona"
import EtapaBox from "../components/EtapaBox"

import texts from "../components/Etapa/content"



const getStage = () => {
  let url = undefined
  if (typeof window !== 'undefined') url = window.location.search.split('?')[1];

  if (url === "1") {
    return Language(texts).etapa1
  } else if (url === "2") {
    return Language(texts).etapa2
  } else if (url === "3") {
    return Language(texts).etapa3
  } else if (url === "4") {
    return Language(texts).etapa4
  } else {
    return Language(texts).etapa1
  }
}

const Etapa = () => {
  const stage = getStage()

  return (
    <Layout>
      <Seo title="Como Funciona" />
      <ComoFunciona />
      <EtapaBox
        title={stage.title}
        subtitle={stage.date}
        image={stage.image}
        text={stage.text}
      >
        <p>{stage.child.content}</p>
        <ol type='i'>
          {stage.child.list.map((item, i) => {
            return (
              <li key={i}>
                {item}
              </li>
            )
          })}
        </ol>
      </EtapaBox>
    </Layout>
  )
}

export default Etapa