import React from 'react';
import styled from 'styled-components'

//Components
import Language from "../Language"
import comoFunciona from '../../assets/images/comoFunciona.png';

//Texts
import texts from "./content"


const ComoFunciona = () => (
    <Container>
      <Content>
        <Title>{Language(texts).title}</Title>
        <Text>{Language(texts).subtitle}</Text>
      </Content>
      <Image src={comoFunciona} alt='' />
    </Container>
)

export default ComoFunciona

const Container = styled.header`
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
  padding: 2rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Content = styled.div`
  width: 50%;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

const Title = styled.h1`
  margin-bottom: 1rem;
  font-family: var(--primary);
  font-size: 4rem;
  line-height: 5rem;

  @media screen and (max-width: 768px) {
    font-size: 2rem;
    line-height: 2.5rem;
  }
`

const Text = styled.p`
  font-family: var(--primaryLight);
  font-size: 3rem;
  color: #929292;
  line-height: 4rem;

  @media screen and (max-width: 768px) {
    font-size: 1.5rem;
    line-height: 2rem;
  }
`

const Image = styled.img`
  width: 40%;

  @media screen and (max-width: 768px) {
    display: none;
  }
`